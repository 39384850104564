import React, { Component } from "react"
import { Link } from "gatsby"

export default class PoemItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHover: false,
    }
  }

  hoverItem(type) {
    this.setState({
      isHover: type,
    })
  }

  toggleItem() {
    this.setState({
      isHover: !this.state.isHover,
    })
  }

  render() {
    const categoryMap = {
      "my passion": "PASSION",
      "my pain": "PAIN",
      "my power": "POWER",
      "my solitude": "SOLITUDE",
      "my spirituality": "SPIRITUALITY",
    }
    const {
      title,
      stylesNum,
      category,
      currentCategory,
      slug,
      excerpt,
    } = this.props
    let showCategory = {}
    if (currentCategory !== "ALL") {
      showCategory =
        categoryMap[category.title] !== currentCategory
          ? { display: "none" }
          : {}
    }

    const hideOnHover = this.state.isHover ? { display: "none" } : {}
    const hideOnNoHover = !this.state.isHover ? { display: "none" } : {}
    return (
      <div
        onMouseEnter={this.hoverItem.bind(this, true)}
        onMouseLeave={this.hoverItem.bind(this, false)}
        onClick={this.toggleItem.bind(this)}
      >
        <div style={hideOnHover}>
          <div
            className={`poem-item-wrap poem-item-${stylesNum}`}
            style={showCategory}
          >
            <div className="poem-item-title">{title}</div>
            <div className="poem-flower-img" />
          </div>
        </div>
        <div className="poem-item-wrap-hover" style={hideOnNoHover}>
          <div
            className="poem-item-excerpt"
            dangerouslySetInnerHTML={{ __html: excerpt.excerpt }}
          />
          <Link to={`/poems/${slug}`} className="poem-item-link">
            Continue Reading
          </Link>
        </div>
      </div>
    )
  }
}
