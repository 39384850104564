const seoContent = {
  siteDescriptions: {
    home:
      'Gabriella is a coach and an author. She is the Founder of Alziari Coaching LLC and recently published her debut poetry collection, "Healing". Gabriella believes that through her words and coaching, she can spread a message of self-empowerment and self-love.',
    default:
      'Gabriella is a coach and an author. She is the Founder of Alziari Coaching LLC and recently published her debut poetry collection, "Healing". Gabriella believes that through her words and coaching, she can spread a message of self-empowerment and self-love.',
  },
}

export default seoContent
