import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PoemItem from "../components/poemItem"
import seoContent from "../helpers/seoContent"

export default class Poems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCategory: "ALL",
    }
  }

  changeCategory(type) {
    this.setState({
      selectedCategory: type,
    })
  }

  isActiveTab(tabType) {
    const activeTabStyles = { fontWeight: 700 }
    return tabType === this.state.selectedCategory ? activeTabStyles : {}
  }

  genStylesNum(num) {
    num += 1
    return !(num % 10) ? 10 : num % 10
  }

  renderPoems() {
    const { data } = this.props
    if (data && data.allContentfulPoem && data.allContentfulPoem.edges) {
      return data.allContentfulPoem.edges.map((poem, i) => {
        let stylesNum = this.genStylesNum(i)
        const { title, excerpt, category, slug } = poem.node
        return (
          <PoemItem
            title={title}
            slug={slug}
            excerpt={excerpt}
            category={category}
            currentCategory={this.state.selectedCategory}
            stylesNum={stylesNum}
            key={i}
          />
        )
      })
    }
  }

  render() {
    const { siteDescriptions } = seoContent
    return (
      <Layout>
        <SEO title="Poems" description={siteDescriptions.default} />
        <div id="poems">
          <div className="poems-header">
            <h1>Poems</h1>
          </div>
          <div className="poems-body">
            <ul className="poems-nav">
              <li
                className="poems-nav-item"
                style={this.isActiveTab("ALL")}
                onClick={this.changeCategory.bind(this, "ALL")}
              >
                All
              </li>
              <li
                className="poems-nav-item"
                style={this.isActiveTab("PASSION")}
                onClick={this.changeCategory.bind(this, "PASSION")}
              >
                My Passion
              </li>
              <li
                className="poems-nav-item"
                style={this.isActiveTab("PAIN")}
                onClick={this.changeCategory.bind(this, "PAIN")}
              >
                My Pain
              </li>
              <li
                className="poems-nav-item"
                style={this.isActiveTab("POWER")}
                onClick={this.changeCategory.bind(this, "POWER")}
              >
                My Power
              </li>
              <li
                className="poems-nav-item"
                style={this.isActiveTab("SOLITUDE")}
                onClick={this.changeCategory.bind(this, "SOLITUDE")}
              >
                My Solitude
              </li>
              <li
                className="poems-nav-item"
                style={this.isActiveTab("SPIRITUALITY")}
                onClick={this.changeCategory.bind(this, "SPIRITUALITY")}
              >
                My Spirituality
              </li>
            </ul>
            <div className="poems-list">{this.renderPoems()}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allContentfulPoem(sort: { fields: [createdAt], order: [DESC] }) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          category {
            title
          }
        }
      }
    }
  }
`
